/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useEffect, useState } from 'react'

import { useRequest } from '../request'
import { useAuth } from '../auth'
import { isVisible } from '@testing-library/user-event/dist/utils'

const UsersContext = createContext({
    users: Array,
    userSelected: Object,
    details: Object,
    getMoreUsers: async () => {},
    selectUser: (value) => {},
    saveUser: (note, needContact, isVisible) => {},
    deleteUserSelected: async () => {},
})

export function UsersContextProvider({ children }) {
    const { handleRequest } = useRequest()
    const { token } = useAuth()

    const [users, setUsers] = useState([])
    const [userSelected, setUserSelected] = useState(null)
    const [page, setPage] = useState(0)

    const getMoreUsers = async () => {
        const response = await handleRequest('get', `admin/users/pro/${page}/25`, null, token)

        if (response?.data) {
            setPage(page + 1)
            setUsers([...users, ...response.data.users])
        }
    }

    const saveUser = async (note, needContact, isVisible) => {
        console.log(isVisible)
        const response = await handleRequest(
            'put',
            `admin/users/${userSelected._id}`,
            { note: note, needContact: needContact, isVisible: isVisible },
            token
        )

        if (response.data) {
            const userUpdated = response.data?.user

            let usersUpdated = [...users]
            users.map((user, index) => {
                if (user._id === userUpdated._id) {
                    usersUpdated[index] = userUpdated
                }
            })

            setUserSelected(userUpdated)
            setUsers(usersUpdated)
        }
        return
    }

    const [details, setDetails] = useState(null)

    const getUserDetails = async () => {
        if (userSelected) {
            const response = await handleRequest('get', `admin/users/${userSelected._id}`, null, token)

            if (response?.data) {
                setDetails(response.data.details)
            }
        }
    }

    useEffect(() => {
        if (userSelected) {
            getUserDetails()
        } else {
            setDetails(null)
        }
    }, [userSelected])

    const deleteUserSelected = async () => {
        if (userSelected) {
            await handleRequest('delete', `admin/users/${userSelected._id}`, {}, token)
            setUserSelected(null)
        }
    }

    return (
        <UsersContext.Provider
            value={{
                users: users,
                userSelected: userSelected,
                details: details,
                getMoreUsers: getMoreUsers,
                selectUser: setUserSelected,
                saveUser: saveUser,
                deleteUserSelected: deleteUserSelected,
            }}
        >
            {children}
        </UsersContext.Provider>
    )
}

export const useUsers = () => useContext(UsersContext)
