import React, { useEffect, useState } from 'react'
import { Auth, Users } from '../../context'

import { Hourglass } from 'lucide-react'
import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'

const Table = () => {
    const { isLogged } = Auth.useAuth()
    const { users, getMoreUsers, selectUser } = Users.useUsers()

    const [canLoadMore, setCanLoadMore] = useState(false)
    const [previousUsers, setPreviousUsers] = useState([])

    useEffect(() => {
        const fetchInitialUsers = async () => {
            if (isLogged) {
                if (previousUsers.length === 0 || previousUsers.length < users.length) {
                    setCanLoadMore(true)
                    await getMoreUsers()
                } else {
                    setCanLoadMore(false)
                }
            }

            setCanLoadMore(false)
        }

        if (isLogged && (previousUsers.length === 0 || previousUsers.length !== users.length)) {
            fetchInitialUsers()
            setPreviousUsers(users)
        }
    }, [users, previousUsers, isLogged])

    const headers = [
        { label: 'Prénom', key: 'firstName' },
        { label: 'Nom', key: 'lastName' },
        { label: 'Email', key: 'email' },
        { label: 'Numéro de Téléphone', key: 'phoneNumber' },
        { label: 'Adresse compléte', key: 'address' },
        { label: 'Date de création du compte', key: 'createdAt' },
        { label: 'Dernière utilisation', key: 'lastUseAt' },
        { label: 'RDV sur les 2 derniers mois', key: 'bookingsLength' },
        { label: 'Utilisation moyenne', key: 'historicsDuration' },
    ]

    const data = users.map((user) => {
        return {
            firstName: user._profil?.firstName || '',
            lastName: user._profil?.lastName || '',
            email: user.email || '',
            phoneNumber: user._profil?.phoneNumber?.phone || '',
            address: user._profil?.address?.formatted || '',
            createdAt: user.createdAt ? dayjs(user.createdAt).locale('FR-fr').format('DD - MM - YYYY') : '',
            lastUseAt: user.lastUseAt ? dayjs(user.lastUseAt).locale('FR-fr').format('DD - MM - YYYY') : '',
            bookingsLength: user.details?.bookings ? user.details?.bookings?.length : '0',
            historicsDuration: user.details?.historics ? user.details?.historics?.length / 60 : '0',
        }
    })

    return (
        <div className="grid gap-2">
            {typeof users === 'object' &&
                users?.map((user) => (
                    <div
                        className="flex items-center justify-between w-full space-x-3"
                        key={user._id}
                        onClick={() => selectUser(user)}
                    >
                        <div className="flex items-center space-x-2">
                            <div
                                className="rounded-full w-[42px] h-[42px] bg-light-gray border border-black border-opacity-10"
                                style={{
                                    backgroundImage: `url(${user._profil?.avatar?.thumbnailUrl})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundSize: 'cover',
                                }}
                                alt="Photo de profil Iléa"
                            />
                            <div className="max-w-[45vw] overflow-hidden">
                                (
                                {!user._profil ? (
                                    user.email
                                ) : (
                                    <>
                                        {user._profil?.firstName} {user._profil?.lastName}
                                    </>
                                )}
                                )
                                <br />
                                <b>{user._profil?.companyName}</b>
                            </div>
                        </div>
                        <div
                            className={`rounded-full px-2 flex items-center h-fit ${
                                user.needContact ? `bg-red-500` : 'bg-green'
                            }`}
                        >
                            {user.needContact ? <>A contacter</> : <>Contacté</>}
                        </div>
                    </div>
                ))}

            <div className="mx-auto mt-10">
                {canLoadMore ? (
                    <>
                        <div className="absolute top-7 right-5">
                            <Hourglass />
                        </div>
                        <p className="text-[20px] my-10 underline text-gray-600">
                            Chargement des utilisateurs {users.length > 0 && 'restants'} ...
                        </p>
                    </>
                ) : (
                    <>
                        {users.length > 0 && (
                            <div className="absolute top-7 right-5">
                                <CSVLink data={data} headers={headers} filename="users.csv">
                                    Exporter CSV
                                </CSVLink>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="mx-auto mt-2">{users.length} utilisateurs</div>
        </div>
    )
}

export default Table
